import React from 'react';
import logo from './logo.svg';
import './App.css';
import './index.css'; // Tell Webpack that Button.js uses these styles
import 'typeface-roboto'
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>

          <br></br>
          <Typography variant="outlined" color="inherit">  Tim Reusing</Typography>
          <p></p>
          <Button variant="outlined" color="secondary">REUSE</Button>


        </p>
      </header>

    </div>
  );
}

export default App;
